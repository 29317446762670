/* eslint-disable */
import Axios from 'axios';
import api from './api';

const RESOURCE_NAME = process.env.VUE_APP_BASE_URL;


export default {
  async register(user, camp) {
    let auth = ""
    let body = {
      "FirstName": user.FirstName,
      "LastName" : user.LastName,
      "Email" : user.email,
      "Password" : user.password,
      "UserType" : user.UserType
    }
    let endpoint = '/login/register'
    if(user.UserType === 'camp' && camp) {
      body.CampName = camp.campName
      body.CampUrl = camp.campURL
      body.NewCamp = true
      endpoint = '/login/registercampuser'
    }
    api.axiosPost(`/login/registercampuser`, body)
   
  },
  async refresh() {
    return api.axiosRefresh()
  },
  async login(username, password) {
    let body = {
          "Email" :username,
          "Password" : password
      }
    return api.axiosPost(`/login/validate`, body)
  
  },
  async resetPasswordEmail(email) {
    let body = {
      email: email
    }
    return api.axiosPost(`/login/resetpassword`, body)
  },
  async resetPassword(email, psw, code) {
    let body = {
      email: email,
      password: psw
    }
    return api.axiosPost(`/login/resetpassword/${code}`, body)
  },
  async logout() {
    api.axiosPost(`/login/logout`, {})
  },
  async getUser() {
    return api.axiosGet(`/login/user`)
  },
};