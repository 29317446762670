/* eslint-disable */
import Axios from 'axios';

const RESOURCE_NAME = process.env.VUE_APP_BASE_URL;
const axiosApiInstance = Axios.create();

async function refresh () {
  return new Promise((resolve, reject) => {
    let rft = $cookies.get('rft')
    let jwt = $cookies.get('cit')

    Axios.post(RESOURCE_NAME+`/login/refresh`,{}, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'refreshtoken' : rft
      }
    })
    .then(t => {
      $cookies.set("cit", t.data.split('|')[1] ,'1min', null, null, null, "Strict")
      $cookies.set("rft", t.data.split('|')[0] , '3d', null, null, null, "Strict")
      resolve( t)
    })
    .catch(error => {
      reject(error)
      console.log("error refresh", error)
    });
  })
}

axiosApiInstance.interceptors.request.use(
  async config => {
    let jwt =  $cookies.get("cit")
    let rft = $cookies.get("rft")
    if(config.url !== `${RESOURCE_NAME}/login/refresh`){

      config.headers = { 
        'Authorization': `Bearer ${jwt}`,
        'refreshtoken' : `${rft}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': RESOURCE_NAME+':7425'
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

axiosApiInstance.interceptors.response.use(
  response => { 
    return response
  },
  error => {
    const originalRequest = error.config
    if(error.response.status === 401 && error.response.config.url !== `${RESOURCE_NAME}/login/refresh`) {
      let rft = $cookies.get('rft')
      let jwt = $cookies.get('cit')

      return Axios.post(RESOURCE_NAME+`/login/refresh`,{}, {
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'refreshtoken' : rft,
        }
      })
      .then(t => {
        $cookies.set("cit", t.data.split('|')[1] ,'3d', null, null, null, "Strict")
        $cookies.set("rft", t.data.split('|')[0] , '3d', null, null, null, "Strict")
        
        let jwt =  $cookies.get("cit")
        let rft = $cookies.get("rft")
        originalRequest.headers = { 
          'Authorization': `Bearer ${jwt}`,
          'refreshtoken' : `${rft}`,
          'Content-Type': 'application/json'

        }
        Axios(originalRequest)
            
      })
    }

    if(error.response.status === 500) {
      return response
    }
  }
)
          
export default {
    async axiosRefresh() {
      const call = await axiosApiInstance.post(RESOURCE_NAME+`/login/refresh`)
      return call
    },
    async axiosGet(url) {
      
      let request = axiosApiInstance(RESOURCE_NAME+`${url}`)
      await request
      return request
    },
    async axiosGetWithParams(url, params) {
      
      let request = axiosApiInstance(RESOURCE_NAME+`${url}`, {params: params})
      await request
      return request
    },
    async axiosPost(url, body) {
      const call = await axiosApiInstance.post(RESOURCE_NAME+`${url}`, body)
      console.log("call", call)
      return call
    },
    async axiosPut(url, body) {
      const call = await axiosApiInstance.put(RESOURCE_NAME+`${url}`, body)
      return call
    },
};